<template>
  <ul class="flex flex-col items-center gap-y-2 px-2 py-4">
    <li v-for="(option, idx) in options" :key="idx">
      <button
        type="button"
        class="flex w-full items-center justify-between gap-2 rounded-full px-7 py-3 text-left hover:bg-neutral-50"
        :class="[isSelected(option) && 'bg-neutral-50']"
        @click="$emit('on-option-click', option.value)"
      >
        <DropdownOption :img="option.img" :label="option.label" />
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { SelectOption } from './Dropdown.vue'

interface Props {
  value: string
  options: Array<SelectOption>
}

const props = defineProps<Props>()

defineEmits(['on-option-click'])

const isSelected = (option: SelectOption) => String(option.value) === props.value
</script>
