<template>
  <div
    ref="wrapper"
    class="relative flex min-w-[169px] items-center rounded-full border-none focus-visible:outline-none"
    :class="[
      background === 'white' ? 'bg-white' : 'bg-neutral-50',
      size === 'sm' ? 'h-auto px-5 py-3 md:pb-3.5' : 'h-12 pl-7 pr-6'
    ]"
  >
    <button
      type="button"
      class="flex w-full items-center justify-between gap-2 text-left"
      @click="dropdownToggle"
    >
      <DropdownOption
        v-if="selectedOption"
        :img="selectedOption.img"
        :label="selectedOption.label"
      />
      <Icon
        name="chevron-down-general"
        class="h-4 w-4 shrink-0 transition"
        :class="{
          'rotate-180': isFocus
        }"
      />
    </button>
    <Modal :show="isFocus && !isMd" variant="sheet" :showClose="false" @onClose="isFocus = false">
      <DropdownList
        :value="modelValue"
        :options="options"
        class="!p-0 !pt-3px"
        @onOptionClick="handleClick"
      />
    </Modal>
    <div
      v-show="isFocus && isMd"
      class="absolute right-0 top-[calc(100%+8px)] z-10 w-full min-w-max rounded-[20px] bg-white shadow-lg"
    >
      <DropdownList :value="modelValue" :options="options" @onOptionClick="handleClick" />
    </div>
  </div>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

export interface SelectOption {
  img?: string
  label: string
  value: string
}

interface DropdownProps {
  background?: 'white' | 'neutral'
  modelValue: string
  options: Array<SelectOption>
  size?: 'sm' | 'md'
}

const props = withDefaults(defineProps<DropdownProps>(), {
  modelValue: '',
  background: 'white',
  size: 'md'
})

const emit = defineEmits(['update:modelValue'])

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const wrapper = ref<null | HTMLElement>(null)

const isFocus = ref(false)

onClickOutside(wrapper, () => {
  setTimeout(() => (isFocus.value = false))
})

function handleClick(e: any) {
  isFocus.value = false

  emit('update:modelValue', e)
}

function dropdownToggle() {
  isFocus.value = !isFocus.value
}

const selectedOption = computed(() =>
  props.options.find((option: SelectOption) => String(option.value) === props.modelValue)
)
</script>
